
import { Component, Vue } from 'vue-property-decorator';
import RouteTitle from '@/components/RouteTitle.vue';

@Component({
  components: {
    RouteTitle,    
  },
})
export default class UserNotAuthorized extends Vue {}
